<template>
  <v-dialog
    max-width="700"
    width="100%"
    value="true"
    persistent
    scrollable
    @keydown.ctrl.83.prevent.stop="save"
    @keydown.meta.83.prevent.stop="save"
    @keydown.esc="close"
  >
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <v-toolbar :color="color" class="mb-2">
          <v-btn icon>
            <v-icon>mdi-calendar-text</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn @click="back" icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="worklog.date"
                label="Datum"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="worklog.date"
              @input="menu = false"
              :first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
          <v-textarea
            outlined
            rows="20"
            label="Worklog"
            v-model="worklog.content"
          ></v-textarea>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn v-if="canDelete" text @click="del" color="error"
          ><v-icon left>mdi-delete</v-icon> Löschen</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn :disabled="!canSave" text @click="save" color="success"
          ><v-icon left>mdi-content-save</v-icon> Speichern</v-btn
        >
        <v-btn text @click="close"
          ><v-icon left>mdi-close</v-icon> Abbrechen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WorklogEdit',
  props: ['id', 'date'],
  data() {
    return {
      color: 'error',
      worklog: {},
      markdown: '',
      loading: false,
      menu: false,
      title: 'Worklog',
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    close() {
      this.$router.push({
        name: 'Worklogs',
      });
    },
    async save() {
      if (this.worklog.date && this.worklog.content) {
        let id = this.worklog.id;
        if (this.worklog.id > 0) {
          await this.apiPut({
            resource: 'worklog/worklog',
            data: this.worklog,
          });
        } else {
          id = (
            await this.apiPost({
              resource: 'worklog/worklog',
              data: this.worklog,
            })
          ).id;
        }

        this.$root.showNotification(
          'Worklog wurde gespeichert!',
          'mdi-content-save',
          'success'
        );
        this.$router.replace({
          name: 'WorklogDetail',
          params: { id: id },
        });
      }
    },
    async del() {
      if (
        await this.$root.confirm({
          message: 'Soll das Worklog wirklich gelöscht werden?',
          color: 'error',
          icon: 'mdi-trash-can',
        })
      ) {
        await this.apiDelete({
          resource: 'worklog/worklog',
          id: this.worklog.id,
        });
        this.$root.showNotification(
          'Worklog wurde gelöscht!',
          'mdi-trash-can',
          'success'
        );
        this.$router.replace({
          name: 'Worklogs',
        });
      }
    },
  },
  computed: {
    canDelete() {
      return this.worklog.id > 0;
    },
    canSave() {
      if (!this.worklog.content || !this.worklog.date) {
        return false;
      }
      return true;
    },
  },
  async created() {
    if (this.id > 0) {
      this.loading = true;
      this.title = 'Worklog editieren';
      this.worklog = await this.apiGet({
        resource: 'worklog/worklog',
        id: this.id,
      });
      this.loading = false;
    } else {
      this.title = 'neues Worklog';
      this.color = 'success';
      this.worklog = {
        id: 0,
        date: this.date,
        content: '',
      };
    }
  },
};
</script>
